<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="lp-form-offer-payment f-form"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <div>
      <div v-text="t('LPOfferPayment.sections.offerInfo.title')" />

      <q-separator class="q-mt-sm" />
    </div>

    <div class="f-form-offer-payment__listing-price">
      <q-icon name="houseWithMoneySign" size="1rem" color="primary" class="q-mr-sm" />
      <span
        class="f-form-offer-payment__listing-price-text"
        v-text="`${t('LPOffer.extra.listingPrice')}:`"
      />
      <span class="f-form-offer-payment__listing-price-amount" v-text="formatPrice(listingPrice)" />
    </div>

    <FInputCurrency
      v-model="formData.amount"
      :label="t('LPOffer.fields.amount.label')"
      outlined
      required="true"
      :rules="[requiredDefault, v => gtDefault(v.toString().replaceAll('.', ''), 0)]"
    />

    <div>
      <div v-text="t('LPOfferPayment.sections.paymentInfo.title')" />

      <q-separator class="q-mt-sm" />
    </div>

    <FInputRadio v-model="paymentMethod" :options="paymentMethodOptions" />

    <template v-if="paymentMethod === 'cashMortgage'">
      <FInputCurrency
        v-model="formData.cashAmount"
        :disable="loading"
        :error="!!errors.cashAmount"
        :error-message="errors.cashAmount?.[0]"
        :label="t('LPOfferPayment.fields.cashAmount.label')"
        outlined
        required="true"
        :rules="[requiredDefault, v => gteDefault(v.toString().replaceAll('.', ''), 0)]"
        @blur="onBlur('cashAmount')"
      />

      <FInputCurrency
        v-model="formData.mortgageAmount"
        :disable="loading"
        :error="!!errors.mortgageAmount"
        :error-message="errors.mortgageAmount?.[0]"
        :label="t('LPOfferPayment.fields.mortgageAmount.label')"
        outlined
        required="true"
        :rules="[requiredDefault, v => gteDefault(v.toString().replaceAll('.', ''), 0)]"
        @blur="onBlur('mortgageAmount')"
      />

      <FInputToggle
        v-model="formData.mortgagePreApproval"
        :disable="loading"
        :label="t('LPOfferPayment.fields.mortgagePreApproval.label')"
        :options="mortgagePreApprovalOptions"
        required="true"
        :rules="[requiredDefault]"
      />
    </template>

    <FInput
      v-if="formData.mortgagePreApproval === '1'"
      v-model="formData.mortgageBankName"
      :label="t('LPOfferPayment.fields.mortgageBankName.label')"
      outlined
      :rules="[requiredDefault]"
      required="true"
    />

    <FInputFile
      v-if="formData.mortgagePreApproval === '1'"
      v-model="formData.mortgagePreApprovalFile"
      clearable
      :disable="loading"
      :display-value="displayValueFile"
      :label="t('LPOfferPayment.fields.mortgagePreApprovalFile.label')"
      outlined
      :rules="[requiredDefault]"
      required="true"
      :accept="acceptedFileTypes"
    >
      <template #prepend>
        <q-icon color="secondary" name="attachment" />
      </template>
    </FInputFile>

    <FInput
      v-model="formData.comments"
      :disable="loading"
      :label="t('LPOffer.fields.comments.label')"
      outlined
      type="textarea"
    />

    <FInputTerms
      v-model="formData.accepts_terms"
      :error-message="errors.accepts_terms?.[0]"
      :disable="loading"
    >
      <template #default="{ urlTerms }">
        <span v-text="t('LPOfferPayment.fields.acceptsTerms.message.one')" />

        <a
          :href="urlTerms"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop
          v-text="t('LPOfferPayment.fields.acceptsTerms.message.two')"
        />
      </template>
    </FInputTerms>

    <FInputTerms
      v-model="formData.accepts_terms_cta"
      :disable="loading"
      :error-message="errors.accepts_terms_cta?.[0]"
    >
      <span v-text="t('LPOfferPayment.fields.acceptsTermsCta.message.one')" />

      <a
        :href="urlAcceptedTermsCta"
        target="_blank"
        rel="noopener noreferrer"
        @click.stop
        v-text="t('LPOfferPayment.fields.acceptsTermsCta.message.two')"
      />
    </FInputTerms>

    <FInputTerms
      v-if="formDataMerged.submitterType === 'agent'"
      v-model="formData.signedBuyerRepresentationAgreement"
      :error-message="errors.signedBuyerRepresentationAgreement?.[0]"
    >
      <span
        class="lp-form-offer-payment__terms"
        v-text="t('LPOfferPayment.fields.signedBuyerRepresentationAgreement.label')"
      />
    </FInputTerms>

    <div ref="elContainerSignature" class="lp-form-offer-payment__signature">
      <div
        class="lp-form-offer-payment__signature-title"
        v-text="t('LPOfferPayment.sections.signature.title')"
      />

      <div
        class="lp-form-offer-payment__signature-instructions"
        v-text="t('LPOfferPayment.sections.signature.subtitle')"
      />

      <div class="lp-form-offer-payment__signature-container" @touchmove.prevent>
        <canvas
          ref="elCanvas"
          class="lp-form-offer-payment__signature-canvas"
          style="touch-action: none; user-select: none"
        />
      </div>

      <q-btn v-bind="btnPropsSignatureClear" @click="onClickSignatureClear" />

      <div class="f-input__label--error" v-text="signatureErrorMsg" />
    </div>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsPrev" @click="onPrev" />
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import SignaturePad from 'signature_pad';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputCurrency from '@/components/Form/input/FInputCurrency.vue';
import FInputFile from '@/components/Form/input/FInputFile.vue';
import FInputRadio from '@/components/Form/input/FInputRadio.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import type {
  OfferFormDvg,
  OfferFormPayment,
} from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useTheme } from '@/composables/theme';
import { useCaptcha } from '@/composables/useCaptcha';
import useFileHandler from '@/composables/useFileHandler';
import useLocalizedLinks from '@/composables/useLocalizedLinks';
import { useUtmSource } from '@/composables/useUtmSource';
import translations from '@/i18n/translations/components/formSteps.json';
import useListingStore from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import type { FormId } from '@/types/formStepsFactory';

const { storeOffer } = useApiListing();
const listingStore = useListingStore();
const { listing, isPreview } = storeToRefs(listingStore);
const { formatPrice } = useTheme();
const { getToken } = useCaptcha();
const { base64ToBlob } = useFileHandler();
const { resolveUtmSource } = useUtmSource();

const { generateLocalizedUrl } = useLocalizedLinks();

const { locale, t } = useI18n(translations);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'prev', p?: typeof formData.value): void;
  (e: 'next'): void;
  (e: 'update-next', p?: typeof formData.value): void;
}>();

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: OfferFormDvg;
}>();

const errors = ref<Partial<Record<keyof typeof formData.value, string[]>>>({});

const signatureErrorMsg = ref('');
const paymentMethod = ref<'cash' | 'cashMortgage'>('cash');

const elCanvas = useTemplateRef('elCanvas');
const signaturePad = ref<SignaturePad | null>(null);

const { elQForm, gt, gte, onValidationError, required } = useFormInputRules();

const gtDefault = gt();
const gteDefault = gte();
const requiredDefault = required();

const loading = ref(false);

const elContainerSignature = useTemplateRef('elContainerSignature');

const listingPrice = computed(() => listing.value?.price || 0);

const formData = ref<OfferFormPayment>({
  amount: listingPrice.value,
  accepts_terms: false,
  accepts_terms_cta: false,
  comments: props.next.comments || '',
  mortgageBankName: props.next.mortgageBankName || '',
  mortgagePreApproval: props.next.mortgagePreApproval || null,
  mortgagePreApprovalFile: props.next.mortgagePreApprovalFile || null,
  signatureFile: props.next.signatureFile || null,
  paymentOption: props.next.paymentOption || null,
  cashAmount: props.next.cashAmount || null,
  mortgageAmount: props.next.mortgageAmount || null,
  signedBuyerRepresentationAgreement: false,
  r_token: '',
  utmSource: resolveUtmSource(),
});

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const formDataMerged = computed(() => ({ ...props.next, ...formData.value }));

const disableSubmitBtn = computed(() => {
  const acceptedTerms = formData.value.accepts_terms && formData.value.accepts_terms_cta;

  if (props.next.submitterType === 'agent') {
    return !(acceptedTerms && formData.value.signedBuyerRepresentationAgreement);
  }

  return !acceptedTerms || loading.value;
});

const urlAcceptedTermsCta = computed(() =>
  generateLocalizedUrl('https://www.altamiraproperties.gr/other-terms')
);

const paymentMethodOptions = computed(() => [
  { label: t('LPOfferPayment.fields.paymentMethodCash.label'), value: 'cash' },
  { label: t('LPOfferPayment.fields.paymentMethodCashMortgage.label'), value: 'cashMortgage' },
]);

const mortgagePreApprovalOptions = computed(() => [
  { label: t('LPOfferPayment.fields.mortgagePreApproval.options.yes'), value: '1' },
  { label: t('LPOfferPayment.fields.mortgagePreApproval.options.no'), value: '0' },
]);

const displayValueFile = computed(
  () =>
    formData.value.mortgagePreApprovalFile?.name ||
    t('LPOfferPayment.fields.mortgagePreApprovalFile.placeholder')
);

const btnPropsSignatureClear = computed(() => ({
  class: 'lp-form-offer-payment__signature-btn',
  icon: 'refreshHalfArrow',
  color: 'primary',
  disable: loading.value,
  dense: true,
  flat: true,
  outline: true,
  label: t('LPOffer.btn.clearSignature'),
  noCaps: true,
  unelevated: true,
}));

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: disableSubmitBtn.value,
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const btnPropsPrev = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  label: t('LPOffer.btn.back'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const acceptedMimeTypes: string[] = [
  // Documents
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.ms-outlook',
  // Office Formats
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // OpenDocument
  'application/vnd.oasis.opendocument.spreadsheet',
  // Archives
  'application/zip',
  'application/vnd.rar',
  // Images
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/tiff',
  // Text files
  'text/plain',
  'text/csv',
];

const acceptedExtensions: string[] = [
  '.msg',
  '.ods',
  '.pdf',
  '.rtf',
  '.doc',
  '.docx',
  '.xls',
  '.ppt',
  '.pptx',
  '.xlsx',
  '.rar',
  '.zip',
  '.gif',
  '.jpg',
  '.jpeg',
  '.png',
  '.tif',
  '.tiff',
  '.csv',
  '.txt',
];

const acceptedFileTypes: string = [...acceptedMimeTypes, ...acceptedExtensions].join(',');

const onPrev = () => {
  emit('prev', formDataMerged.value);
};

const onClickSignatureClear = () => {
  signaturePad.value?.clear();
};

const onSubmit = async () => {
  if (isPreview.value) return;

  if (!signaturePad.value || signaturePad.value.isEmpty()) {
    signatureErrorMsg.value =
      locale.value === 'en' ? 'The field is required' : 'Το πεδίο είναι απαραίτητο';

    elContainerSignature.value?.scrollIntoView({ behavior: 'smooth' });

    return;
  }

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  errors.value = {};

  const rToken = await getToken('submitOffer');
  formData.value.r_token = rToken;

  if (paymentMethod.value === 'cash') {
    formData.value.cashAmount = formData.value.amount;
  }

  const data = new FormData();

  // Recursive function to flatten and append FormData
  const appendToFormData = (object: any, parentKey: string = '') => {
    if (Array.isArray(object)) {
      object.forEach((value, index) => {
        appendToFormData(value, `${parentKey}[${index}]`);
      });
    } else if (object instanceof Blob) {
      data.append(parentKey, object);
    } else if (typeof object === 'object' && object !== null) {
      // recursively
      Object.entries(object).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        appendToFormData(value, newKey);
      });
    } else if (typeof object === 'boolean') {
      data.append(parentKey, object ? '1' : '0');
    } else {
      data.append(parentKey, object instanceof Blob ? object : object?.toString() || '');
    }
  };

  const payload = {
    ...formDataMerged.value,
    signature: base64ToBlob(signaturePad.value.toDataURL(), 'image/png'),
  };

  // flatten
  appendToFormData(payload);

  storeOffer(data)
    .then(() => {
      emit('next');
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 409:
            listingStore.loadListing();

            emit('close');
            break;

          case 422:
            errors.value = response.data.errors;
            break;

          default:
            console.error('Unexpected error:', error);
            break;
        }
      } else {
        // Handle network or unexpected errors
        console.error('Network error:', error);
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const initCanvas = () => {
  if (!elCanvas.value) return;

  // Get the device pixel ratio
  const ratio = Math.max(window.devicePixelRatio || 1, 1);

  elCanvas.value.width = elCanvas.value.offsetWidth * ratio;
  elCanvas.value.height = elCanvas.value.offsetHeight * ratio;
  elCanvas.value?.getContext('2d')?.scale(ratio, ratio);

  // signature options
  signaturePad.value = new SignaturePad(elCanvas.value, {
    maxWidth: 3,
    minWidth: 1,
  });
};

const onBlur = (key: keyof typeof formData.value) => {
  errors.value[key] = undefined;
};

onMounted(() => {
  initCanvas();
});

watch(
  () => formData.value.mortgagePreApproval,
  v => {
    if (v === '1') return;

    formData.value.mortgagePreApprovalFile = null;
    formData.value.mortgageBankName = '';
  }
);

watch(paymentMethod, v => {
  if (v === 'cash') {
    formData.value.cashAmount = null;
    formData.value.mortgageAmount = null;
    formData.value.mortgagePreApproval = null;
    formData.value.mortgageBankName = '';
  }
});
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-form-offer-payment {
  .lp-form-offer-payment__terms a {
    margin-left: 0.25rem;
    color: c.$primary;
    text-decoration: none;
  }

  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 0.5rem;
  }

  .lp-form-offer-payment__signature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background: #fbfbfb;
    border: 1px solid c.$util-2;
    border-radius: 8px;
  }

  .lp-form-offer-payment__signature-title {
    font-size: 0.875rem;
    font-weight: 700;
    color: c.$secondary;
  }

  .lp-form-offer-payment__signature-instructions {
    margin-bottom: 10px;
    font-size: 0.75rem;
    font-weight: 600;
    color: c.$accent;
  }

  .lp-form-offer-payment__signature-container {
    position: relative;
    width: 100%;
    max-width: 1280px;
    max-height: 300px;
    aspect-ratio: 16 / 6;
    overflow: hidden;
    background: white;
    border: 2px dashed #ddd;
    border-radius: 8px;

    &.is-empty {
      border: 2px dashed c.$negative;
      border-color: c.$negative-1;
    }
  }

  .lp-form-offer-payment__signature-canvas {
    display: block;
    width: 100%;
    height: 100%;
  }

  .lp-form-offer-payment__signature-btn {
    display: flex;
    align-self: flex-end;
    margin-top: 5px;
  }

  .lp-form-offer-payment__signature-btn .q-btn {
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .f-form-offer-payment__listing-price {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0;
    background: c.$primary-3;
    border-radius: map.get($radius-sizes, 'sm');
  }

  .f-form-offer-payment__listing-price-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: c.$secondary;
  }

  .f-form-offer-payment__listing-price-amount {
    font-size: 1.125rem;
    font-weight: 800;
    color: c.$primary;
  }
}
</style>
