<template>
  <div class="lp-offer-additional">
    <q-list>
      <q-expansion-item
        :label="t('LPOffer.sections.personalInfo.title')"
        default-opened
        dense-toggle
        expand-separator
      >
        <q-card flat>
          <q-card-section>
            <div v-if="modelValue.buyers && modelValue.buyers.length" class="f-form_grid_columns">
              <FInput
                v-model="modelValue.buyers[0].firstName"
                :error="!!errors?.[`buyers.0.firstName`]"
                :error-message="errors?.[`buyers.0.firstName`]?.[0]"
                :label="t('LPOffer.fields.firstName.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
              <FInput
                v-model="modelValue.buyers[0].lastName"
                :error="!!errors?.['buyers.0.lastName']"
                :error-message="errors?.['buyers.0.lastName']?.[0]"
                :label="t('LPOffer.fields.lastName.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.buyers[0].fathersName"
                :error="!!errors?.['buyers.0.fathersName']"
                :error-message="errors?.['buyers.0.fathersName']?.[0]"
                :label="t('LPOffer.fields.fathersName.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.buyers[0].mothersName"
                :error="!!errors?.['buyers.0.mothersName']"
                :error-message="errors?.['buyers.0.mothersName']?.[0]"
                :label="t('LPOffer.fields.mothersName.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInputDatePicker
                v-model="modelValue.buyers[0].dateOfBirth"
                :error="!!errors?.['buyers.0.dateOfBirth']"
                :error-message="errors?.['buyers.0.dateOfBirth']?.[0]"
                :label="t('LPOffer.fields.dateOfBirth.label')"
                outlined
                required="true"
                :rules="[requiredDefault, v => dateYearBeforeDefault(v, 18, 'DD/MM/YYYY')]"
                readonly
                :q-date-props="{
                  options: optionsFn,
                  navigationMaxYearMonth: dateYearSubtract(18),
                  defaultYearMonth: dateYearSubtract(18),
                  modelValue: modelValue.buyers[0].dateOfBirth,
                }"
              />

              <FInput
                v-model="modelValue.buyers[0].placeOfBirth"
                :error="!!errors?.['buyers.0.placeOfBirth']"
                :error-message="errors?.['buyers.0.placeOfBirth']?.[0]"
                :label="t('LPOffer.fields.placeOfBirth.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInputPhone
                v-model="modelValue.buyers[0].mobileNumber"
                :error="!!errors?.['buyers.0.mobileNumber']"
                :error-message="errors?.['buyers.0.mobileNumber']?.[0]"
                :label="t('LPOffer.fields.mobileNumber.label')"
                outlined
                :required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.buyers[0].email"
                :error="!!errors?.['buyers.0.email']"
                :error-message="errors?.['buyers.0.email']?.[0]"
                :label="t('LPOffer.fields.email.label')"
                lazy-rules
                outlined
                :rules="[emailDefault, v => notEqualDefault(v, user?.email)]"
              />

              <FInput
                v-model="modelValue.buyers[0].idNumber"
                :error="!!errors?.['buyers.0.idNumber']"
                :error-message="errors?.['buyers.0.idNumber']?.[0]"
                :label="t('LPOffer.fields.idNumber.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.buyers[0].vatNumber"
                :error="!!errors?.['buyers.0.vatNumber']"
                :error-message="errors?.['buyers.0.vatNumber']?.[0]"
                :label="t('LPOffer.fields.vatNumber.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        :label="t('LPOffer.sections.residentialInfo.title')"
        dense-toggle
        default-opened
        expand-separator
      >
        <q-card flat>
          <q-card-section v-if="modelValue.buyers && modelValue.buyers.length">
            <div class="f-form_grid_columns">
              <FInput
                v-model="modelValue.buyers[0].country"
                :error="!!errors?.['buyers.0.country']"
                :error-message="errors?.['buyers.0.country']?.[0]"
                :label="t('LPOffer.fields.country.label')"
                :rules="[requiredDefault]"
                required="true"
                outlined
              />

              <FInput
                v-model="modelValue.buyers[0].city"
                :error="!!errors?.['buyers.0.city']"
                :error-message="errors?.['buyers.0.city']?.[0]"
                :label="t('LPOffer.fields.city.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>

            <FInput
              v-model="modelValue.buyers[0].street"
              :error="!!errors?.['buyers.0.street']"
              :error-message="errors?.['buyers.0.street']?.[0]"
              :label="t('LPOffer.fields.street.label')"
              outlined
              required="true"
              :rules="[requiredDefault]"
            />

            <div class="f-form_grid_columns">
              <FInput
                v-model="modelValue.buyers[0].number"
                :error="!!errors?.['buyers.0.number']"
                :error-message="errors?.['buyers.0.number']?.[0]"
                :label="t('LPOffer.fields.number.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.buyers[0].postalCode"
                :error="!!errors?.['buyers.0.postalCode']"
                :error-message="errors?.['buyers.0.postalCode']?.[0]"
                :label="t('LPOffer.fields.postalCode.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        :label="t('LPOffer.sections.companyInfo.title')"
        dense-toggle
        default-opened
        expand-separator
      >
        <q-card flat>
          <q-card-section v-if="modelValue.companyInfo">
            <FInput
              v-model="modelValue.companyInfo.name"
              :error="!!errors?.['companyInfo.name']"
              :error-message="errors?.['companyInfo.name']?.[0]"
              :label="t('LPOffer.fields.companyName.label')"
              outlined
              required="true"
              :rules="[requiredDefault]"
            />

            <div class="f-form_grid_columns">
              <FInput
                v-model="modelValue.companyInfo.beneficiaryFirstName"
                :error="!!errors?.[`companyInfo.beneficiaryFirstName`]"
                :error-message="errors?.[`companyInfo.beneficiaryFirstName`]?.[0]"
                :label="t('LPOffer.fields.beneficiaryFirstName.label')"
                :rules="[requiredDefault]"
                required="true"
                outlined
              />

              <FInput
                v-model="modelValue.companyInfo.beneficiaryLastName"
                :error="!!errors?.[`companyInfo.beneficiaryLastName`]"
                :error-message="errors?.[`companyInfo.beneficiaryLastName`]?.[0]"
                :label="t('LPOffer.fields.beneficiaryLastName.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>

            <div class="f-form_grid_columns">
              <FInputPhone
                v-model="modelValue.companyInfo.phoneNumber"
                :error="!!errors?.['companyInfo.phoneNumber']"
                :error-message="errors?.['companyInfo.phoneNumber']?.[0]"
                :label="t('LPOffer.fields.companyPhoneNumber.label')"
                outlined
                :required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.companyInfo.commercialRegistryNumber"
                :error="!!errors?.['companyInfo.commercialRegistryNumber']"
                :error-message="errors?.['companyInfo.commercialRegistryNumber']?.[0]"
                :label="t('LPOffer.fields.commercialRegistryNumber.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <q-expansion-item
        :label="t('LPOffer.sections.companyAddress.title')"
        dense-toggle
        default-opened
        expand-separator
      >
        <q-card flat>
          <q-card-section v-if="modelValue.companyInfo">
            <div class="f-form_grid_columns">
              <FInput
                v-model="modelValue.companyInfo.country"
                :error="!!errors?.['companyInfo.country']"
                :error-message="errors?.['companyInfo.country']?.[0]"
                :label="t('LPOffer.fields.country.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.companyInfo.city"
                :error="!!errors?.['companyInfo.city']"
                :error-message="errors?.['companyInfo.city']?.[0]"
                :label="t('LPOffer.fields.city.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>

            <FInput
              v-model="modelValue.companyInfo.street"
              :error="!!errors?.['companyInfo.street']"
              :error-message="errors?.['companyInfo.street']?.[0]"
              :label="t('LPOffer.fields.street.label')"
              outlined
              required="true"
              :rules="[requiredDefault]"
            />

            <div class="f-form_grid_columns">
              <FInput
                v-model="modelValue.companyInfo.number"
                :error="!!errors?.['companyInfo.number']"
                :error-message="errors?.['companyInfo.number']?.[0]"
                :label="t('LPOffer.fields.number.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />

              <FInput
                v-model="modelValue.companyInfo.postalCode"
                :error="!!errors?.['companyInfo.postalCode']"
                :error-message="errors?.['companyInfo.postalCode']?.[0]"
                :label="t('LPOffer.fields.postalCode.label')"
                outlined
                required="true"
                :rules="[requiredDefault]"
              />
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputDatePicker from '@/components/Form/input/FInputDatePicker.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import type { OfferFormCompany } from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translations from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import type { Next } from '@/types/formStepsFactory';
import { dateYearSubtract } from '@/utils/time';

defineProps<{
  next?: Next;
  errors: Partial<Record<keyof OfferFormCompany | string, string[]>>;
}>();

const { user } = storeToRefs(useAuthStore());

const modelValue = defineModel<OfferFormCompany>('modelValue', {
  required: true,
});

const { t } = useI18n(translations);
const { email, neq, required, dateYearBefore } = useFormInputRules();

const requiredDefault = required();
const emailDefault = email();
const dateYearBeforeDefault = dateYearBefore();
const notEqualDefault = neq();
const optionsFn = (date: string) => dateYearBeforeDefault(date, 18, 'YYYY/MM/DD') === true; // TODO: move 18 to config, maybe BE
</script>

<style lang="scss">
.lp-offer-additional {
  .q-item {
    align-items: center;
    justify-content: space-between;
  }

  .q-expansion-item__toggle-icon {
    color: $primary;
  }

  .q-field__append {
    font-size: 0.875rem;
    color: $secondary;
  }
}

.lp-offer-additional__buyer-header {
  display: flex;
  gap: 0 2rem;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
}

.lp-offer-additional__buyer-header-text {
  font-size: 1rem;
  font-weight: 700;
  color: $primary;
}

.lp-offer-additional__buyer-remove-btn {
  font-size: 0.75rem;
  font-weight: 700;
  color: $negative;
}

.lp-offer-additional__buyer-add-btn {
  width: 100%;

  .q-btn {
    width: 100%;
  }
}

.lp-offer-additional__buyer-section-title {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: $secondary;

  .q-separator {
    margin: 0.5rem 0 1rem;
  }
}
</style>
