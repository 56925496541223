<template>
  <q-form
    :id="btnPropsNext.form"
    ref="elQForm"
    class="f-form"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <LPFOIndividual
      v-if="formData.customerType === 'individual'"
      v-model="formData"
      v-model:add-buyer="addBuyer"
      :errors="errors"
    />

    <LPFOPersonalBusinessDvg
      v-else-if="formData.customerType === 'personalBusiness'"
      v-model="formData"
      :errors="errors"
    />

    <LPFOCompanyDvg
      v-else-if="formData.customerType === 'company'"
      v-model="formData"
      :errors="errors"
    />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn
        v-if="formData.customerType === 'individual'"
        v-bind="btnPropsAddBuyer"
        @click="addBuyer = true"
      />

      <div>
        <q-btn v-bind="btnPropsPrev" @click="onPrev" />
        <q-btn v-bind="btnPropsNext" />
      </div>
    </Teleport>
  </q-form>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPFOCompanyDvg from '@/components/ListingPage/Form/LPFormOfferDvg/LPFOCompanyDvg.vue';
import LPFOIndividual from '@/components/ListingPage/Form/LPFormOfferDvg/LPFOIndividualDvg.vue';
import LPFOPersonalBusinessDvg from '@/components/ListingPage/Form/LPFormOfferDvg/LPFOPersonalBusinessDvg.vue';
import type { OfferFormDvg } from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translations from '@/i18n/translations/components/formSteps.json';
import type { FormId, Next } from '@/types/formStepsFactory';

const { t } = useI18n(translations);

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: OfferFormDvg;
}>();

const emit = defineEmits<{
  (e: 'next', p: typeof formData.value): void;
  (e: 'prev', p: typeof formData.value): void;
  (e: 'updateNext', p: Next): void;
}>();

const { elQForm, onValidationError } = useFormInputRules();

const addBuyer = ref(false);
const customerTypeMobileExpand = ref(false);

const formData = ref<OfferFormDvg>({
  ...props.next,
});

const errors = ref<Partial<Record<keyof typeof formDataMerged.value | string, string[]>>>({});

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const btnPropsAddBuyer = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  label: t('LPOffer.btn.addBuyer'),
  noCaps: true,
  outline: true,
  padding: '0.8rem 1.5rem',
  textColor: 'primary',
  unelevated: true,
}));

const btnPropsNext = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: !formData.value.customerType,
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.continue'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const btnPropsPrev = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: t('LPOffer.btn.back'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const formDataMerged = computed(() => ({
  ...props.next,
  ...formData.value,
}));

const onPrev = () => {
  emit('prev', { ...formData.value });
};

const onSubmit = async () => {
  const isValid = await elQForm.value?.validate();

  if (!isValid) return;

  emit('next', formDataMerged.value);
};

const generateBuyer = () => ({
  vatNumber: '',
  idNumber: '',
  mobileNumber: '',
  occupation: '',
  firstName: '',
  lastName: '',
  fathersName: '',
  mothersName: '',
  placeOfBirth: '',
  dateOfBirth: '',
  country: '',
  city: '',
  street: '',
  number: '',
  postalCode: '',
  ownershipPercentage: null,
  email: '',
});

const onUpdateCustomerType = () => {
  customerTypeMobileExpand.value = false;

  switch (formData.value.customerType) {
    case 'individual':
      if ('companyInfo' in formData.value) {
        delete formData.value.companyInfo;
      }

      if (!formData.value.buyers?.length) {
        formData.value.buyers = [generateBuyer()];
      }

      break;
    case 'personalBusiness':
      if (!formData.value.buyers?.length) {
        formData.value.buyers = [generateBuyer()];
      } else if (formData.value.buyers.length > 1) {
        formData.value.buyers.splice(1);
      }

      formData.value.companyInfo = {
        beneficiaryFirstName: '',
        beneficiaryLastName: '',
        name: '',
        phoneNumber: '',
        country: '',
        city: '',
        street: '',
        number: '',
        postalCode: '',
        commercialRegistryNumber: '',
      };
      break;
    case 'company':
      if (!formData.value.buyers?.length) {
        formData.value.buyers = [generateBuyer()];
      } else if (formData.value.buyers.length > 1) {
        formData.value.buyers.splice(1);
      }

      formData.value.companyInfo = {
        beneficiaryFirstName: '',
        beneficiaryLastName: '',
        name: '',
        phoneNumber: '',
        representativePhoneNumber: '',
        country: '',
        city: '',
        street: '',
        number: '',
        postalCode: '',
        commercialRegistryNumber: '',
      };
      break;
    default:
      break;
  }

  emit('updateNext', { customerType: formData.value.customerType });
};

onMounted(() => {
  onUpdateCustomerType();
});
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-offer-additional {
  .q-item {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
  }

  .q-expansion-item__toggle-icon {
    color: c.$primary;
  }
}

.lp-offer-additional__buyer-header {
  display: flex;
  gap: 0 2rem;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
}

.lp-offer-additional__buyer-header-text {
  font-size: 1rem;
  font-weight: 700;
  color: c.$primary;
}

.lp-offer-additional__buyer-remove-btn {
  font-size: 0.75rem;
  font-weight: 700;
  color: c.$negative;
}

.lp-offer-additional__buyer-add-btn {
  width: 100%;

  .q-btn {
    width: 100%;
  }
}

#lp-offer-additional {
  .dialog-form__card {
    .dialog-form--actions {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      div:last-child {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 100%;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.lp-form-offer-actions {
  width: 100%;
}

.making-offer-as--text {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.6;
  color: c.$secondary;
}

.lp-form-offer-actions__container {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.25rem 0;
  margin: 1.5rem 0;

  @media (max-width: $breakpoint-sm) {
    margin: 0.75rem 0;
  }

  .q-separator {
    flex-grow: 1;
  }
}
</style>
