<template>
  <FInput ref="f-input-date-picker" v-bind="fInputProps" :model-value="modelValueFormatted">
    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
      <q-date
        v-bind="props.qDateProps"
        v-model="modelValue"
        mask="YYYY-MM-DD"
        :locale="calendarLocaleData(locale)"
        :style="{ width: qDateWidth }"
      >
        <template v-for="(_, slotName) in getSlots($slots)" #[slotName]>
          <slot :name="slotName" />
        </template>

        <div class="row items-center justify-end">
          <q-btn v-close-popup label="Close" color="primary" flat />
        </div>
      </q-date>
    </q-popup-proxy>
  </FInput>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import type { QDateProps, QDateSlots } from 'quasar';
import { type ComponentPublicInstance, computed, type ShallowRef, useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import type { FInputDateProps, FInputProps } from '@/types/formInput';
import { calendarLocaleData, toDateFormat } from '@/utils/time';

const { locale } = useI18n();

const props = defineProps<FInputDateProps>();

const fInputProps = computed(() => {
  const p: Partial<FInputDateProps> & FInputProps = { ...props };

  delete p.qDateProps;

  return p;
});

const modelValue = defineModel<QDateProps['modelValue']>('modelValue', { required: true });

const modelValueFormatted = computed(() => modelValue.value && toDateFormat(modelValue.value));

const elFInputDatePicker = useTemplateRef('f-input-date-picker');

const { width } = useElementSize(elFInputDatePicker as ShallowRef<ComponentPublicInstance>);

const qDateWidth = computed(() => `${width.value}px`);

// hacks needed to preserve slot name and prop types.
const getSlots = (slots: unknown) => slots as QDateSlots;
</script>
