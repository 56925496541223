import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormAuctionParticipation from '@/components/LPFormAuctionParticipation.vue';
import { useStepsAuth } from '@/factories/formStepsFactory/auth';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpAuctionBid = () => {
  const factoryId: FactoryId = 'lp-auction-bid';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });
  const { getFormStepsAuth } = useStepsAuth();

  const getFormStepsLpAuctionBid = (): FormStepsFactory => {
    const authFormSteps = getFormStepsAuth();

    return computed<Step[]>(() => [
      ...authFormSteps.value,
      {
        component: () => LPFormAuctionParticipation,
        formId: 'lp-auction-bid',
        title: t('title.auctionBid'),
        subtitle: t('subtitle.auctionBid'),
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: t('subtitle.auctionBidError') },
            title: { text: t('title.auctionBidError') },
          },
          formInfoMsgPropsSuccess: {
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: { text: t('subtitle.auctionBidSuccess') },
            title: { text: t('title.auctionBidSuccess') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return {
    factoryId,
    getFormStepsLpAuctionBid,
  };
};
