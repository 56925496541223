<template>
  <div class="f-input f-input-terms">
    <div class="f-input__label" v-text="label" />

    <q-checkbox
      v-bind="qCheckBoxProps"
      v-model="modelValue"
      :color="errorMessage ? 'negative' : 'primary'"
      :disable="disable"
      :false-value="false"
      keep-color
      size="md"
      :true-value="true"
    >
      <template #default>
        <div class="f-input-terms--container">
          <slot :url-privacy="localizedPrivacyUrl" :url-terms="localizedTermsUrl">
            <span v-text="t('agreeToOur')" />

            <a
              :href="localizedTermsUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop
              v-text="t('termsAndConditions')"
            />

            <span v-text="t('and')" />

            <a
              :href="localizedPrivacyUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop
              v-text="t('privacyPolicy')"
            />
          </slot>
        </div>
      </template>
    </q-checkbox>

    <div
      v-if="errorMessage"
      class="q-field__messages col error q-field__bottom"
      v-text="errorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import type { QCheckboxProps } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocalizedLinks from '@/composables/useLocalizedLinks';
import translations from '@/i18n/translations/components/listingCtaTerms.json';
import type { FInputTermsProps } from '@/types/formInput';

const props = defineProps<FInputTermsProps>();

const modelValue = defineModel<QCheckboxProps['modelValue']>('modelValue', { required: true });

const qCheckBoxProps = computed(() => {
  const p = { ...props };

  delete p.label;

  return p;
});

const { t } = useI18n(translations);
const { localizedTermsUrl, localizedPrivacyUrl } = useLocalizedLinks();
</script>

<style lang="scss">
.f-input-terms {
  width: 100%;

  .f-input-terms--container > * {
    margin-right: 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.6;

    &:last-child {
      margin-right: 0;
    }
  }

  .f-input-terms--container a {
    color: $primary;
    text-decoration: none;
  }

  .error {
    padding-top: 0;
    color: $negative;
  }

  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 8px 7px 8px 8px;
  }
}
</style>
