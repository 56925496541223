import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFOAdditionalDvg from '@/components/ListingPage/Form/LPFormOfferDvg/LPFOAdditionalDvg.vue';
import LPFOPaymentDvg from '@/components/ListingPage/Form/LPFormOfferDvg/LPFOPaymentDvg.vue';
import LPOfferStepOneDvg from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferStepOneDvg.vue';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Next, Step } from '@/types/formStepsFactory';

import { useStepsAuth } from './auth';

export const useStepsLpOffer = () => {
  const factoryId: FactoryId = 'lp-offer';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpOffer = (): FormStepsFactory => {
    const { getFormStepsAuth } = useStepsAuth();
    const authFormSteps = getFormStepsAuth();

    return computed<Step[]>(() => {
      return [
        ...authFormSteps.value,
        {
          component: () => LPOfferStepOneDvg,
          formId: 'lp-offer',
          subtitle: t('subtitle.offer'),
          title: t('title.offer'),
        },
        {
          component: () => LPFOAdditionalDvg,
          formId: 'lp-offer-additional',
          subtitle: (state: Next) => t(`subtitle.offerAdditionalWith.${state.customerType}`),
          title: (state: Next) => t(`title.offerAdditionalWith.${state.customerType}`),
        },
        {
          component: () => LPFOPaymentDvg,
          formId: 'lp-offer-payment',
          subtitle: (state: Next) => t(`subtitle.offerPaymentWith.${state.customerType}`),
          title: (state: Next) => t(`title.offerPaymentWith.${state.customerType}`),
        },
        {
          component: () => FormStepperCallback,
          componentProps: {
            formInfoMsgPropsError: {
              qIconProps: {
                color: 'negative',
                name: 'warning',
                size: '5rem',
              },
              subtitle: { text: t('subtitle.offerError') },
              title: { text: t('title.offerError') },
            },
            formInfoMsgPropsSuccess: {
              qIconProps: {
                color: 'primary',
                name: 'success',
                size: '5rem',
              },
              subtitle: { text: t('subtitle.offerSuccess') },
              title: { text: t('title.offerSuccess') },
            },
          },
          formId: 'callback',
        },
      ];
    });
  };

  return { factoryId, getFormStepsLpOffer };
};
