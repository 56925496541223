<template>
  <div class="renovation-cost-modal" :class="{ 'full-height': $q.screen.gt.sm }">
    <!-- Renovation cost modal header -->
    <div
      class="row justify-between items-center bg-white q-pa-md header no-wrap"
      :class="{ 'q-mb-sm': $q.screen.gt.sm }"
    >
      <div class="row items-center no-wrap">
        <q-icon name="renovation" size="55px" color="secondary" class="q-mr-md" />
        <div
          class="text-h3 text-secondary"
          v-text="t('section.renovation.modal.title.renovationCost')"
        />
      </div>

      <q-icon
        v-if="$q.screen.gt.sm"
        class="close-btn cursor-pointer"
        name="cancel"
        color="primary"
        size="24px"
        @click="emit('close')"
      />
    </div>
    <!-- Renovation cost modal content -->
    <div
      :class="[
        $q.screen.gt.sm ? 'row no-wrap q-pb-lg' : 'col content-small',
        { 'q-mx-sm q-pa-lg': $q.screen.gt.sm },
      ]"
    >
      <div
        class="bg-white cost-info-container"
        :class="[
          $q.screen.gt.sm ? 'col-3 small border-radius-md' : 'row no-wrap justify-between q-mb-lg',
          { 'q-px-xl': !$q.screen.gt.sm && $q.screen.gt.xs },
        ]"
      >
        <div v-if="$q.screen.gt.sm" class="text-h4 text-secondary q-pa-lg row">
          <div class="header-icon q-mr-md" />
          <div v-text="t('section.renovation.modal.title.totalCost')" />
        </div>

        <q-separator v-if="$q.screen.gt.sm" />
        <!-- Renovation cost modal Basic doughnut -->
        <div :class="$q.screen.gt.sm ? 'q-px-lg q-pb-md' : 'q-pa-lg'">
          <HalfDoughnutChart
            :id="'basic-renovation'"
            :key="'basic-renovation'"
            :datasets="costBreakDown.basic"
            :title="'Basic'"
            :subtitle="totalCostBasic"
            :class="{ 'renovation-cost-doughnut': !$q.screen.lt.md }"
          />
          <div class="row justify-between">
            <div v-for="(item, index) in costBreakDown.basic" :key="index">
              <div class="row items-center no-wrap items-baseline">
                <span class="cost-dot q-mr-sm" :style="`background-color: ${item.color}`" />
                <span class="text-body1 text-secondary" v-text="item.label" />
              </div>

              <div class="text-subtitle2-bolder q-pl-md" v-text="formatPrice(item.value)" />
            </div>
          </div>
        </div>
        <q-separator v-if="$q.screen.gt.sm" class="q-mx-md" />
        <!-- Renovation cost modal Premium doughnut -->
        <div :class="$q.screen.gt.sm ? 'q-px-lg q-pb-md' : 'q-pa-lg'">
          <HalfDoughnutChart
            id="premium-renovation"
            key="premium-renovation"
            :datasets="costBreakDown.premium"
            :subtitle="totalCostPremium"
            title="Premium"
            :class="{ 'renovation-cost-doughnut': !$q.screen.lt.md }"
          />
          <div class="row justify-between">
            <div v-for="(item, index2) in costBreakDown.premium" :key="index2">
              <div class="row items-center no-wrap items-baseline">
                <span class="cost-dot q-mr-sm" :style="`background-color: ${item.color}`" />
                <span class="text-body1 text-secondary">{{ item.label }}</span>
              </div>
              <div class="text-subtitle2-bolder q-pl-md" v-text="formatPrice(item.value)" />
            </div>
          </div>
        </div>
      </div>
      <!-- Renovation cost modal analysis tables -->
      <div :class="{ 'col-9 q-pl-lg': $q.screen.gt.sm }">
        <LPRenovationCostAnalysis
          v-if="!loading && renovationData"
          class="bg-white cost-info-container"
          :class="{ 'border-radius-md': !$q.screen.lt.md }"
          :renovation-cost-analysis="renovationData"
        />
        <div
          v-if="!loading && renovationData"
          class="q-py-sm"
          :class="$q.screen.lt.md ? 'bg-white q-pb-lg3 q-pl-lg' : 'q-pl-xs'"
        >
          <span
            class="text-caption text-secondary"
            v-text="t('section.renovation.modal.disclaimer')"
          />
        </div>
      </div>
    </div>
    <!-- Renovation cost modal sticky close button (mobile) -->
    <div v-if="!$q.screen.gt.sm" class="close-btn-fixed q-pa-md">
      <q-btn v-bind="btnPropsBtnCloseModal" @click="emit('close')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPRenovationCostAnalysis from '@/components/ListingPage/fragments/LPRenovationCostAnalysis.vue';
import HalfDoughnutChart from '@/components/Reusable/HalfDoughnutChart.vue';
import { useApiListing } from '@/composables/api/listing';
import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/listingPage.json';
import type { ListingRenovationCost, ListingRenovationCostTypes } from '@/types/api/listing';

const props = defineProps<{
  listingId: number | string;
  renovationCostsSummary: ListingRenovationCostTypes;
}>();

const { t } = useI18n(translations);

const { showRenovationCost } = useApiListing();

const renovationData = ref<ListingRenovationCost>();
const loading = ref(false);

const { formatPrice } = useTheme();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const costBreakDown = computed(() => {
  let basicStructuralTotalCost = 0;
  let basicInteriorTotalCost = 0;
  let premiumStructuralTotalCost = 0;
  let premiumInteriorTotalCost = 0;
  if (!loading.value && renovationData.value) {
    if (renovationData.value.structural) {
      Object.values(renovationData.value.structural).forEach(structuralItem => {
        basicStructuralTotalCost += structuralItem.basic;
        premiumStructuralTotalCost += structuralItem.premium;
      });
    }
    if (renovationData.value.interior) {
      Object.values(renovationData.value.interior).forEach(interiorItem => {
        basicInteriorTotalCost += interiorItem.basic;
        premiumInteriorTotalCost += interiorItem.premium;
      });
    }
  }
  return {
    basic: [
      {
        label: t('section.renovation.modal.subtitle.structural'),
        color: '#613CF8',
        value: basicStructuralTotalCost,
      },
      {
        label: t('section.renovation.modal.subtitle.interior'),
        color: '#C0B1FC',
        value: basicInteriorTotalCost,
      },
    ],
    premium: [
      {
        label: t('section.renovation.modal.subtitle.structural'),
        color: '#C18610',
        value: premiumStructuralTotalCost,
      },
      {
        label: t('section.renovation.modal.subtitle.interior'),
        color: '#FFD844',
        value: premiumInteriorTotalCost,
      },
    ],
  };
});

const totalCostBasic = computed(() => formatPrice(props.renovationCostsSummary.basic));
const totalCostPremium = computed(() => formatPrice(props.renovationCostsSummary.premium));

const btnPropsBtnCloseModal = computed(() => ({
  class: 'text-body2-bold border-radius-xl full-width',
  color: 'primary',
  label: t('btn.renovation.closeModal.label'),
  noCaps: true,
  padding: '0.8rem',
  unelevated: true,
  loading: loading.value,
}));

onMounted(() => {
  loading.value = true;

  showRenovationCost(props.listingId)
    .then(({ data }) => {
      renovationData.value = data.data;
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

.renovation-cost-modal {
  background-color: $util-3;

  .header {
    box-shadow: 0 4px 8px rgb(219 225 245 / 25%);
  }

  .close-btn {
    padding: 10px;
    background-color: $primary-4;
    border-radius: map.get($radius-sizes, rounded);
  }

  .cost-dot {
    width: 8px;
    height: 8px;
    border-radius: map.get($radius-sizes, rounded);
  }

  .cost-info-container {
    @media (min-width: $breakpoint-sm) {
      &.small {
        max-width: 400px;
      }
    }
  }

  .close-btn-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    border-radius: map.get($radius-sizes, lg) map.get($radius-sizes, lg) 0 0;
    box-shadow: 0 -4px 20px 10px rgb(219 225 245 / 35%);
  }

  .content-small {
    margin-bottom: 80px;
  }

  .renovation-cost-doughnut {
    max-width: 280px;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.header-icon {
  width: 6px;
  height: 2rem;
  background-color: $primary;
}
</style>
