<template>
  <div class="f-input f-input-radio">
    <div v-if="label" class="f-input__label" v-text="label" />

    <q-field
      :model-value="modelValue"
      v-bind="qFieldProps"
      borderless
      no-error-icon
      :disable="disable"
    >
      <template #control>
        <div class="f-input-radio__container">
          <q-option-group v-model="modelValue" type="radio" :options="options" />
        </div>
      </template>
    </q-field>
  </div>
</template>

<script setup lang="ts">
import type { QFieldProps, QOptionGroupProps } from 'quasar';
import { computed } from 'vue';

import i18n from '@/i18n';
import type { FInputRadioProps } from '@/types/formInput';

const props = withDefaults(defineProps<FInputRadioProps>(), {
  options: () => [
    { label: i18n.global.t('Yes'), value: 'Yes' },
    { label: i18n.global.t('No'), value: 'No' },
  ],
});

const qFieldProps = computed(() => {
  const propsLocal: QFieldProps & Partial<FInputRadioProps> = { ...props };

  delete propsLocal.label;
  delete propsLocal.options;

  return propsLocal;
});

const modelValue = defineModel<QOptionGroupProps['modelValue']>('modelValue', { required: true });
</script>

<style lang="scss">
@use 'sass:map';

.f-input-radio__container {
  display: flex;
  gap: 1.5rem;
  width: 100%;

  .q-option-group {
    display: flex;
  }
}
</style>
