<template>
  <div class="f-input f-input-toggle">
    <div v-if="label" class="f-input__label" v-text="label" />

    <q-field v-model="modelValue" v-bind="qFieldProps" borderless no-error-icon>
      <template #control>
        <div class="f-input-toggle__container">
          <q-btn
            v-for="option in options"
            :key="`${option.value}`"
            v-bind="option"
            :class="{
              'f-input-toggle__btn': true,
              'f-input-toggle__btn--selected': option.value === modelValue,
            }"
            no-caps
            unelevated
            @click="modelValue = option.value"
          />
        </div>
      </template>
    </q-field>
  </div>
</template>

<script setup lang="ts">
import type { QFieldProps } from 'quasar';
import { computed } from 'vue';

import i18n from '@/i18n';
import type { FInputToggleProps } from '@/types/formInput';

const props = withDefaults(defineProps<FInputToggleProps>(), {
  options: () => [
    { label: i18n.global.t('Yes'), value: 'Yes' },
    { label: i18n.global.t('No'), value: 'No' },
  ],
});

const qFieldProps = computed(() => {
  const propsLocal: QFieldProps & Partial<FInputToggleProps> = { ...props };

  delete propsLocal.label;
  delete propsLocal.options;

  return propsLocal;
});

const modelValue = defineModel<(typeof props.options)[0]['value']>('modelValue');

const optionsLength = computed(() => props.options.length);
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.f-input-toggle__container {
  display: grid;
  grid-template-columns: repeat(v-bind('optionsLength'), minmax(0, 1fr));
  gap: 0.5rem;
  align-items: stretch;
  width: 100%;
}

.f-input-toggle__btn {
  height: 3.5rem;
  font-weight: 600;
  line-height: 1.6;
  border: 1px solid c.$util-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.f-input-toggle__btn--selected {
  background: $primary-1;
  border: 1px solid c.$primary;
}

.f-input-toggle .q-field__native {
  padding: 0;
}
</style>
