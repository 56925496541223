import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

import { useStepsAuth } from './auth';

export const useStepsLpUnlockDocuments = () => {
  const factoryId: FactoryId = 'lp-unlock-documents';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });

  const getFormStepsLpUnlockDocuments = (): FormStepsFactory => {
    const { getFormStepsAuth } = useStepsAuth();
    const authFormSteps = getFormStepsAuth();

    return computed<Step[]>(() => {
      const authFormStepsLocal = [...authFormSteps.value];

      authFormStepsLocal[0].subtitle = t('subtitle.unlockDocumentsLogin');

      return [
        ...authFormStepsLocal,
        {
          component: () => FormStepperCallback,
          componentProps: {
            formInfoMsgPropsError: {
              qIconProps: {
                color: 'negative',
                name: 'warning',
                size: '5rem',
              },
              subtitle: { text: t('subtitle.unlockDocumentsError') },
              title: { text: t('title.unlockDocumentsError') },
            },
          },
          formId: 'callback',
        },
      ];
    });
  };

  return { factoryId, getFormStepsLpUnlockDocuments };
};
