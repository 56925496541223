<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form lp-open-day__form"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <div v-if="$q.screen.gt.sm" class="lp-open-day__time-slot-container">
      <div class="lp-open-day__time-slot-date-container">
        <q-icon class="lp-open-day__time-slot-icon" color="primary" name="event" />

        <span
          class="lp-open-day__time-slot-date-text"
          v-text="toDateFormat(formData.date, 'DD.MM.YYYY')"
        />
      </div>

      <q-separator vertical dense color="primary" />

      <div class="lp-open-day__time-slot-time-text" v-text="formData.timeSlot" />
    </div>

    <LPFODSlotPicker v-else v-model="formData" />

    <FormFieldsetRenderer :disable="loading" :fieldsets="fieldsets" />

    <FInputTerms
      v-model="formData.accepts_terms"
      :disable="loading"
      :error-message="errors.accepts_terms?.[0]"
    >
      <template #default="{ urlTerms }">
        <span v-text="t('LPOpenDay.fields.acceptsTerms.message.one')" />

        <a
          :href="urlTerms"
          target="_blank"
          rel="noopener noreferrer"
          @click.stop
          v-text="t('LPOpenDay.fields.acceptsTerms.message.two')"
        />

        <span v-text="t('LPOpenDay.fields.acceptsTerms.message.three')" />
      </template>
    </FInputTerms>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script lang="ts" setup>
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FormFieldsetRenderer from '@/components/Form/FormFieldsetRenderer.vue';
import FInput from '@/components/Form/input/FInput.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import LPFODSlotPicker from '@/components/ListingPage/Form/fragments/LPFormOpenDay/LPFODSlotPicker.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import { useUtmSource } from '@/composables/useUtmSource';
import translation from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type {
  FInputPhoneProps,
  FInputProps,
  FInputSelectProps,
  FormFieldInput,
  FormFieldset,
} from '@/types/formInput';
import type { FormId, Next } from '@/types/formStepsFactory';
import { toDateFormat } from '@/utils/time';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: Next;
  data?: Record<string, any>;
}>();

type Errors = Record<
  | Exclude<keyof typeof formData.value, 'contact'>
  | 'contact.firstName'
  | 'contact.lastName'
  | 'contact.email'
  | 'contact.phone',
  string[]
>;

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'next', p: Next): void;
}>();

const { resolveUtmSource } = useUtmSource();
const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);
const { storeOpenDay } = useApiListing();
const listingStore = useListingStore();
const { listing, isPreview } = storeToRefs(listingStore);
const { getToken } = useCaptcha();
const { t } = useI18n(translation);

const { email, elQForm, neq, onValidationError, required } = useFormInputRules();
const { user } = storeToRefs(useAuthStore());

const emailDefault = email();
const notEqualDefault = neq();
const requiredDefault = required();

const bus = useEventBus(formStepperKey);

const loading = ref(false);

const errors = ref<Partial<Errors>>({});

type SubmitterType = 'agent' | 'individual';

type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

type FormData = {
  accepts_terms: boolean;
  contact: Contact;
  date: string;
  r_token: string;
  listingId: string | number;
  utmSource: string | null;
  submitterCompanyName: string;
  submitterType: SubmitterType | null;
  timeSlot: string;
};

const formData = ref<FormData>({
  accepts_terms: false,
  contact: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
  date: props.data?.date || '',
  timeSlot: props.data?.timeSlot || '',
  r_token: '',
  submitterCompanyName: '',
  submitterType: null,
  listingId: listing.value?.id ?? -1,
  utmSource: resolveUtmSource(),
});

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: !formData.value.accepts_terms || !(formData.value.date && formData.value.timeSlot),
  form: `f-${props.formId}`,
  label: t('LPOpenDay.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const submitterTypeOptions = computed(() => [
  {
    label: t('LPOpenDay.fields.submitterType.options.individual'),
    value: 'individual',
  },
  {
    label: t('LPOpenDay.fields.submitterType.options.agent'),
    value: 'agent',
  },
]);

const fieldsCommon = computed(() => [
  {
    component: FInputSelect,
    modelValue: formData.value.submitterType,
    behavior: 'menu',
    error: !!errors.value.submitterType?.[0],
    errorMessage: errors.value.submitterType?.[0],
    emitValue: true,
    label: t('LPOpenDay.fields.submitterType.label'),
    mapOptions: true,
    noErrorIcon: true,
    outlined: true,
    options: submitterTypeOptions.value,
    required: true,
    rules: [requiredDefault],
    popupContentClass: 'f-input-select__content-class',
    'onUpdate:modelValue': (v: typeof formData.value.submitterType) => {
      formData.value.submitterType = v;

      if (v === 'individual') {
        formData.value.submitterCompanyName = '';
      }
    },
  } as FormFieldInput<FInputSelectProps>,
]);

const fieldsContact = computed(() => [
  {
    component: FInput,
    error: !!errors.value['contact.firstName']?.[0],
    errorMessage: errors.value['contact.firstName']?.[0],
    label: t('LPOpenDay.fields.firstName.label'),
    modelValue: formData.value.contact.firstName,
    outlined: true,
    'onUpdate:modelValue': (v: typeof formData.value.contact.firstName) => {
      formData.value.contact.firstName = v;
    },
  } as FormFieldInput<FInputProps>,
  {
    component: FInput,
    error: !!errors.value['contact.lastName']?.[0],
    errorMessage: errors.value['contact.lastName']?.[0],
    label: t('LPOpenDay.fields.lastName.label'),
    modelValue: formData.value.contact.lastName,
    outlined: true,
    'onUpdate:modelValue': (v: typeof formData.value.contact.lastName) => {
      formData.value.contact.lastName = v;
    },
  } as FormFieldInput<FInputProps>,
  {
    component: FInput,
    error: !!errors.value['contact.email']?.[0],
    errorMessage: errors.value['contact.email']?.[0],
    label: t('LPOpenDay.fields.email.label'),
    modelValue: formData.value.contact.email,
    outlined: true,
    rules: [emailDefault, v => notEqualDefault(v, user.value?.email)],
    'onUpdate:modelValue': (v: typeof formData.value.contact.email) => {
      formData.value.contact.email = v;
    },
  } as FormFieldInput<FInputProps>,
  {
    component: FInputPhone,
    error: !!errors.value['contact.phone']?.[0],
    errorMessage: errors.value['contact.phone']?.[0],
    label: t('LPOpenDay.fields.phone.label'),
    modelValue: formData.value.contact.phone,
    'onUpdate:modelValue': (v: typeof formData.value.contact.phone) => {
      formData.value.contact.phone = v;
    },
  } as FormFieldInput<FInputPhoneProps>,
]);

const fieldsAuthUser = computed(() => [
  {
    component: FInput,
    disable: true,
    label: t('LPOpenDay.fields.firstName.label'),
    modelValue: user.value?.first_name,
    outlined: true,
  } as FormFieldInput<FInputProps>,
  {
    component: FInput,
    disable: true,
    label: t('LPOpenDay.fields.lastName.label'),
    modelValue: user.value?.last_name,
    outlined: true,
  } as FormFieldInput<FInputProps>,
  {
    component: FInput,
    disable: true,
    label: t('LPOpenDay.fields.email.label'),
    modelValue: user.value?.email,
    outlined: true,
  } as FormFieldInput<FInputProps>,
  {
    component: FInputPhone,
    disable: true,
    label: t('LPOpenDay.fields.phone.label'),
    modelValue: user.value?.phone,
  } as FormFieldInput<FInputPhoneProps>,
]);

const fieldsets = computed<FormFieldset[]>(() => {
  const fieldsetsLocal: FormFieldset[] = [
    {
      fields: fieldsCommon.value,
    },
  ];

  const fieldsAuthUserLocal = [...fieldsAuthUser.value];

  switch (formData.value.submitterType) {
    case 'agent':
      fieldsAuthUserLocal.push({
        component: FInput,
        error: !!errors.value.submitterCompanyName?.[0],
        errorMessage: errors.value.submitterCompanyName?.[0],
        label: t('LPOpenDay.fields.submitterCompanyName.label'),
        modelValue: formData.value.submitterCompanyName,
        outlined: true,
        'onUpdate:modelValue': (v: typeof formData.value.submitterCompanyName) => {
          formData.value.submitterCompanyName = v;
        },
        rules: [requiredDefault],
      } as FormFieldInput<FInputProps>);

      fieldsetsLocal.push(
        {
          class: 'f-form_grid_columns',
          fields: fieldsAuthUserLocal,
          legend: t('LPOpenDay.legend.agent'),
        },
        {
          class: 'f-form_grid_columns',
          fields: fieldsContact.value,
          legend: t('LPOpenDay.legend.individual'),
          separator: true,
        }
      );
      break;

    case 'individual':
      fieldsetsLocal.push({
        class: 'f-form_grid_columns',
        fields: fieldsAuthUserLocal,
        legend: t('LPOpenDay.legend.individual'),
        separator: true,
      });
      break;

    default:
      break;
  }

  return fieldsetsLocal;
});

const onSubmit = async () => {
  errors.value = {};

  if (isPreview.value) return;

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  formData.value.r_token = await getToken('submitOpenDay');

  storeOpenDay({ ...formData.value, ...(props.next?.formData || {}) })
    .then(() => {
      emit('next', props.next || {});
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 409:
            listingStore.loadListing();

            emit('close');
            break;

          case 422:
            errors.value = response.data.errors;
            break;

          default:
            console.error('Unexpected error:', error);
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-open-day') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;
      case 'complete':
        loading.value = false;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-open-day__time-slot-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.25rem;
  font-size: 1rem;
  color: $primary;
  background: $primary-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.lp-open-day__time-slot-date-container {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}

.lp-open-day__checkbox-participation {
  align-items: center;

  .q-checkbox__label {
    font-size: 0.875rem;
    font-weight: 800;
    color: #000;
  }
}
</style>
