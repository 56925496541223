<template>
  <div class="lp-unlock-documents">
    <FileModalError v-model="error" />

    <DialogFormStepper v-model="dialog" :factory-id="factoryId" :steps="formStepsUnlockDocuments" />

    <div class="listing-body__container--header">
      <q-icon class="listing-body__icon--header" name="documentHouse" />
      <span class="listing-body__text--header" v-text="t('section.documents.title')" />
    </div>

    <div v-if="(user || isPreview) && documents.length > 0">
      <div class="row q-pa-md bg-util-3 border-radius-top-sm documents">
        <div
          class="col-8 col-md-8 col-sm-6 self-center text-left text-body2-bolder text-secondary"
          v-text="t('section.documents.table.cols.0')"
        />
        <div
          class="col-4 col-md-4 col-sm-6 self-center text-right text-body2-bolder text-secondary"
          v-text="t('section.documents.table.cols.1')"
        />
      </div>

      <div class="scroll border-util-2 border-radius-bot-sm documents-scroll">
        <q-virtual-scroll
          v-slot="{ item, index }: { item: ListingDocument; index: number }"
          :items="documents"
          :scroll-target="scrollTarget"
          separator
        >
          <ListingCtaDocumentItem
            :key="index"
            :disabled="isPreview"
            :document="item"
            @error="error = true"
          />
        </q-virtual-scroll>
      </div>
    </div>

    <div v-else class="lp-cta-unlock-documents">
      <div class="text-left">
        <div class="text-body2 text-accent" v-text="t('section.documents.unlockTitle')" />
      </div>

      <div
        class="col-md-4 col-sm-4 col-xs-12"
        :class="{ 'text-right self-center': $q.screen.gt.xs }"
      >
        <q-btn v-bind="btnPropsBtnUnlock" @click="dialog = true" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import ListingCtaDocumentItem from '@/components/ListingPage/fragments/LPDocumentItem.vue';
import FileModalError from '@/components/Reusable/FileModalError.vue';
import { useStepsLpUnlockDocuments } from '@/factories/formStepsFactory/lpUnlockDocuments';
import translations from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';
import type { ListingDocument } from '@/types/api/listing';

const { t } = useI18n(translations);
const { factoryId, getFormStepsLpUnlockDocuments } = useStepsLpUnlockDocuments();

const { user } = storeToRefs(useAuthStore());
const { documents, isPreview, listing } = storeToRefs(useListingStore());

if (!listing.value) {
  throw new Error('Listing is undefined');
}

const formStepsUnlockDocuments = getFormStepsLpUnlockDocuments();

const dialog = ref(false);

const error = ref(false);
const loading = ref(false);
const scrollTarget = ref('');

const btnPropsBtnUnlock = computed(() => ({
  class: `text-body2-bold border-radius-xl ${Screen.lt.sm && 'full-width'}`,
  color: 'primary',
  label: t('btn.unlockDocuments.label'),
  noCaps: true,
  padding: '0.8rem 1.5rem',
  type: 'submit',
  icon: 'unlock',
  unelevated: true,
  loading: loading.value,
  disabled: isPreview.value || loading.value,
}));
</script>

<style lang="scss">
.lp-unlock-documents {
  .documents {
    border-top: 1px solid $util-2;
    border-right: 1px solid $util-2;
    border-left: 1px solid $util-2;
  }

  .lp-cta-unlock-documents {
    display: flex;
    gap: 3rem;

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      gap: 2rem;
    }
  }
}
</style>
