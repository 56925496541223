<template>
  <q-dialog
    :id="id"
    :class="`dialog-form dialog-form--${id}`"
    v-bind="qDialogProps"
    :persistent="qDialogProps.persistent || loading"
    transition-duration="300"
  >
    <q-card class="dialog-form__card" v-bind="qCardProps">
      <q-btn
        v-if="btnClose"
        class="dialog-form__close-btn"
        color="primary-3"
        :disable="loading"
        icon="cancel"
        round
        size="sm"
        text-color="secondary"
        unelevated
        @click="modelValue = false"
      />

      <q-card-section class="dialog-form--header q-py-none">
        <slot name="header" :loading="loading">
          <div v-if="qIconProps || title" class="dialog-form__header">
            <q-icon v-if="qIconProps" v-bind="qIconProps" />

            <span v-if="title" class="dialog-form__header--title" v-text="title" />
          </div>

          <div v-if="subtitle" class="dialog-form__header--subtitle" v-text="subtitle" />
        </slot>
      </q-card-section>

      <q-card-section class="dialog-form--container">
        <slot :loading="loading" name="default" />
      </q-card-section>

      <q-space v-if="maximized" />

      <q-card-actions class="dialog-form--actions">
        <!-- use teleport -->
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { type QBtn, type QDialogProps, Screen } from 'quasar';
import { computed } from 'vue';

import type { DialogFormProps } from '@/types/dialogForm';

interface Props extends DialogFormProps {}

const props = withDefaults(defineProps<Props>(), {
  btnClose: true,
  maximized: Screen.lt.md,
});

const modelValue = defineModel<boolean>('modelValue');

const qDialogProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _props: QDialogProps & Partial<DialogFormProps> = { ...props };

  delete _props.loading;
  delete _props.qCardProps;
  delete _props.subtitle;
  delete _props.title;

  return _props;
});
</script>

<style lang="scss">
@use 'sass:map';

.dialog-form__card {
  width: 490px;
  padding: 1rem 0.5rem;

  .dialog-form--header,
  .dialog-form--container,
  .dialog-form--actions {
    padding: 1rem;
    font-weight: 600;
  }

  .dialog-form--header {
    padding-top: 0.5rem;
  }

  .dialog-form--actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 0.5rem;

    .q-btn:nth-child(n) {
      flex: 1;
    }
  }

  .dialog-form--container {
    overflow: hidden auto;
  }
}

.dialog-form__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;

  .q-icon {
    font-size: 1.715em;
  }
}

.dialog-form__header {
  position: relative;
  display: flex;
  align-items: center;
}

.dialog-form__header--title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
  color: $secondary;
}

.dialog-form__header--subtitle {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.6;
  color: $accent;
  text-align: center;
}

.dialog-form-header__btn--close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .q-icon {
    width: 2rem;
    height: 2rem;
  }
}

.dialog-form--actions {
  display: grid;

  // grid-template-columns is declared inline
  gap: 0.5rem;
  width: 100%;
}

.dialog-form.fullscreen {
  .dialog-form__card {
    display: flex;
    flex-direction: column;
    border-radius: map.get($radius-sizes, 'sm');
  }
}
</style>
