<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form f-form-offer"
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <div>
      <template v-if="$q.screen.lt.md">
        <div class="making-offer-as--text" v-text="t('LPOffer.fields.customerType.label')" />
        <q-field
          :model-value="formData.customerType"
          borderless
          no-error-icon
          color="secondary"
          :rules="[requiredDefault]"
        >
          <q-list class="customer-type-mobile--list">
            <q-expansion-item
              v-model="customerTypeMobileExpand"
              expand-icon="arrow_drop_down"
              group="customerTypes"
              :label="customerTypeMobileLabel"
            >
              <q-option-group
                v-model="formData.customerType"
                class="customer-type-mobile--option-group"
                color="primary"
                left-label
                :options="customerTypeOptions"
              />
            </q-expansion-item>
          </q-list>
        </q-field>
      </template>

      <FInputSelect
        v-else
        v-model="formData.customerType"
        emit-value
        hide-bottom-space
        :label="t('LPOffer.fields.customerType.label')"
        map-options
        no-error-icon
        :options="customerTypeOptions"
        outlined
        :placeholder="t('LPOffer.fields.customerType.placeholder')"
        popup-content-class="f-input-select__content-class"
        :required="true"
        :rules="[requiredDefault]"
      />
    </div>

    <FInputToggle
      v-model="formData.submitterType"
      class="f-form-offer__checkbox-participation"
      :label="t('LPOffer.fields.submitterType.label')"
      :options="submitterTypeOptions"
      :required="true"
      :rules="[requiredDefault]"
    />

    <template v-if="formData.submitterType === 'agent'">
      <div v-text="t('LPOffer.sections.representativeInfo.title')" />

      <q-separator />

      <div class="f-form_grid_columns">
        <FInput
          :model-value="user?.first_name"
          disable
          :label="t('LPOffer.fields.firstName.label')"
          outlined
          :required="true"
        />

        <FInput
          :model-value="user?.last_name"
          disable
          :label="t('LPOffer.fields.lastName.label')"
          outlined
          :required="true"
        />
      </div>

      <div class="f-form_grid_columns">
        <FInput
          :model-value="user?.email"
          disable
          :label="t('LPOffer.fields.email.label')"
          outlined
          :required="true"
        />

        <FInputPhone
          :model-value="user?.phone"
          disabled
          :label="t('LPOffer.fields.phone.label')"
          outlined
          :required="true"
        />
      </div>

      <FInput
        v-model="formData.submitterCompanyName"
        :label="t('LPOffer.fields.submitterCompanyName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />
    </template>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <div class="f-form-offer__actions">
        <q-btn v-bind="btnPropsSubmit" />
      </div>
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import type { OfferFormDvg } from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translations from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';
import type { FormId } from '@/types/formStepsFactory';

type FormData = Pick<
  OfferFormDvg,
  'listingId' | 'submitterType' | 'submitterCompanyName' | 'customerType'
>;

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: FormData;
  prev?: FormData;
}>();

const emit = defineEmits<{
  (e: 'next', p: typeof formData.value): void;
}>();

const { user } = storeToRefs(useAuthStore());
const { listing } = storeToRefs(useListingStore());

const { t } = useI18n(translations);

const { onValidationError, required } = useFormInputRules();

const requiredDefault = required();
const customerTypeMobileExpand = ref(false);

const formData = ref<Partial<FormData>>({
  listingId: listing.value!.id,
  submitterType: props.next?.submitterType || null,
  submitterCompanyName: props.next?.submitterCompanyName || '',
  customerType: props.next?.customerType,
});

const customerTypeMobileLabel = computed(() => {
  let tKey = '';

  if (!formData.value.customerType) {
    tKey = 'LPOffer.fields.customerType.placeholder';
  } else {
    tKey = `LPOffer.fields.customerType.options.${formData.value.customerType}`;
  }

  return t(tKey);
});

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const submitterTypeOptions = computed(() => [
  {
    label: t('LPOffer.fields.submitterType.options.agent'),
    value: 'agent',
  },
  {
    label: t('LPOffer.fields.submitterType.options.individual'),
    value: 'individual',
  },
]);

const customerTypeOptions = computed(() => [
  { label: t('LPOffer.fields.customerType.options.individual'), value: 'individual' },
  { label: t('LPOffer.fields.customerType.options.personalBusiness'), value: 'personalBusiness' },
  { label: t('LPOffer.fields.customerType.options.company'), value: 'company' },
]);

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl full-width',
  color: 'primary',
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.continue'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const onSubmit = () => {
  if (formData.value.submitterType !== 'agent') formData.value.submitterCompanyName = '';

  emit('next', { ...props.next, ...props.prev, ...formData.value });
};

watch(
  () => formData.value.customerType,
  v => {
    if (v) {
      formData.value.submitterCompanyName = '';
      customerTypeMobileExpand.value = false;
    }
  }
);
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.f-form-offer__actions {
  width: 100%;
}

.f-form-offer__checkbox-participation {
  align-items: center;

  .q-checkbox__label {
    font-size: 0.875rem;
    font-weight: 800;
    color: #000;
  }
}

.customer-type-mobile--list {
  width: 100%;
  color: c.$secondary;
  border: 1px solid c.$util-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.customer-type-mobile--option-group {
  color: c.$secondary;

  .q-radio {
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0.75rem 0.25rem 1rem;
  }
}
</style>
