<template>
  <div class="bg-white column lp-l-mobile-media-gallery">
    <q-bar class="bg-white tabs-header fixed-top z-index-5">
      <div class="col row justify-center">
        <q-tabs
          v-model="tab"
          dense
          active-bg-color="primary"
          active-color="white"
          indicator-color="transparent"
          align="justify"
          class="mobile-media-tabs"
          :breakpoint="0"
        >
          <template v-for="control in mediaControls" :key="control.value">
            <q-tab
              v-if="control.visible"
              :ripple="false"
              :name="control.value"
              class="q-px-lg q-mx-xs"
            >
              <span class="text-body2-bold text-capitalize" v-text="control.label" />
            </q-tab>
          </template>
        </q-tabs>
      </div>
    </q-bar>
    <div class="col content-container">
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="photos" class="q-pa-none">
          <template v-for="img in props.listing.images.photos" :key="img.id">
            <img alt="property image" :src="img.sizes[imageSize]" class="full-width" />
          </template>
        </q-tab-panel>
        <q-tab-panel name="mapPhotos" class="q-pa-none">
          <template v-for="img in props.listing.images.mapPhotos" :key="img.id">
            <img alt="property map image" :src="img.sizes[imageSize]" class="full-width" />
          </template>
        </q-tab-panel>
        <q-tab-panel
          v-if="hasMatterport"
          name="matterportVideo"
          class="row justify-center q-pa-none"
        >
          <iframe
            id="matterport-video"
            class="content-item"
            frameBorder="0"
            :src="matterportVideoUrl"
            title="matterport video"
          />
        </q-tab-panel>
        <q-tab-panel v-if="areaVideoUrl" name="areaVideo" class="row justify-center q-pa-none">
          <iframe
            id="area-video"
            class="content-item"
            frameBorder="0"
            :src="areaVideoUrl"
            title="area video"
          />
        </q-tab-panel>
        <q-tab-panel v-if="hasDroneVideo" name="droneVideo" class="row justify-center q-pa-none">
          <iframe
            id="drone-video"
            class="content-item"
            frameBorder="0"
            :src="droneVideoUrl"
            title="drone video"
          />
        </q-tab-panel>
        <q-tab-panel name="floorPlans" class="q-pa-none">
          <template v-for="img in props.listing.images.floorPlans" :key="img.id">
            <img alt="property floor image" :src="img.sizes[imageSize]" class="full-width" />
          </template>
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div class="mobile-media-buttons z-index-3">
      <q-btn v-bind="btnPropsBtnBack" @click="onClickBtnBack" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { Screen } from 'quasar';
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { type MediaControl, useListingMedia } from '@/composables/listingMedia';
import useGTM from '@/composables/useGTM';
import useImageSizeResolver from '@/composables/useImageSizeResolver';
import type { Listing } from '@/types';

interface Props {
  listing: Listing;
}

const props = defineProps<Props>();
const { t } = useI18n();
const { t: tGlobal } = useI18n();
const { imageSize } = useImageSizeResolver();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const tab = defineModel<MediaControl['value']>('tab');

const btnPropsBtnBack = computed(() => ({
  class: 'text-body2-bold border-radius-xl full-width',
  color: 'primary',
  label: tGlobal('backToDetails'),
  icon: 'arrow_left',
  noCaps: true,
  padding: '0.8rem',
  unelevated: true,
  outline: true,
}));

const onClickBtnBack = () => {
  emit('close');
};

const {
  areaVideoUrl,
  droneVideoUrl,
  matterportVideoUrl,
  hasMatterport,
  hasDroneVideo,
  mediaControls,
} = useListingMedia(props.listing);

watch(
  () => tab.value,
  v => {
    switch (v) {
      case 'droneVideo':
        useGTM('drone_video_opened');
        break;
      case 'areaVideo':
        useGTM('area_video_opened');
        break;
      case 'floorPlans':
        useGTM('floor_plans_opened');
        break;
      case 'matterportVideo':
        useGTM('3d_tour_opened');
        break;
      default:
        useGTM('image_gallery_opened');
        break;
    }
  },
  { immediate: true }
);

const onResize = useDebounceFn(() => {
  if (Screen.gt.sm) {
    emit('close');
  }
}, 150);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss">
@use 'sass:map';

.lp-l-mobile-media-gallery {
  margin-bottom: 56px;

  .q-tab {
    border: 1px solid $util-2;
    border-radius: map.get($radius-sizes, xl);
    transition: none;

    &.q-tab--active {
      border: 1px solid $primary;
    }
  }

  .tabs-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: 56px;
    box-shadow: 0 4px 8px rgb(219 225 245 / 25%);
  }

  .content-container {
    padding-top: 56px;
  }

  .q-tab-panel {
    margin-bottom: 4.6875rem;
  }

  .content-item {
    width: 90vw;
    max-width: 1920px;
    height: calc(100vh - 136px);
  }

  .mobile-media-buttons {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background: $white;
    border-radius: map.get($radius-sizes, lg) map.get($radius-sizes, lg) 0 0;
    box-shadow: 0 -4px 20px 10px rgb(219 225 245 / 35%);
  }

  #photo,
  #floor-plan {
    padding: 50px 0 80px;
  }

  #matterport-video,
  #area-video,
  #drone-video {
    padding: 50px 0;
  }

  #floor-plan .q-carousel__slide {
    background-repeat: no-repeat;
    background-size: contain;
  }

  #photo .q-icon,
  #floor-plan .q-icon {
    font-size: 12px;
  }

  .floor-plan-carousel {
    .q-panel.scroll {
      overflow: hidden;
    }

    .q-carousel__navigation--bottom {
      bottom: 32px;
    }
  }

  .mobile-media-tabs {
    overflow: auto;

    .q-tabs__arrow {
      display: none;
    }
  }
}
</style>
