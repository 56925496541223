<template>
  <div class="sp-filters-btn-actions">
    <q-btn v-bind="btnPropsClearFilters" @click="onClickBtnClearFilters" />
    <q-btn v-bind="btnPropsApplyFilters" @click="onClickBtnApplyFilters" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  clearCustomText?: string;
  applyCustomText?: string;
  showPulseEffect?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(['apply', 'clear']);
const { t } = useI18n();

const btnPropsApplyFilters = computed(() => ({
  class: `text-body2-bold border-radius-xl ${props.showPulseEffect && 'sp-filters-btn-actions__pulse'}`,
  color: 'primary',
  label: props.applyCustomText ? props.applyCustomText : t('apply'),
  noCaps: true,
  padding: '0.5rem 1.5rem',
  textColor: 'white',
  unelevated: true,
}));

const btnPropsClearFilters = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: props.clearCustomText ? props.clearCustomText : t('clear'),
  noCaps: true,
  padding: '0.5rem 1.5rem',
  flat: true,
  unelevated: true,
}));

const onClickBtnApplyFilters = () => {
  emit('apply');
};

const onClickBtnClearFilters = () => {
  emit('clear');
};
</script>

<style lang="scss">
.sp-filters-btn-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.sp-filters-btn-actions__pulse {
  animation: pulse-primary 2s 1;
}

@keyframes pulse-primary {
  0% {
    box-shadow: 0 0 0 0 $primary-1;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(0 0 0 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
  }
}
</style>
