<template>
  <div class="lp-offer__form">
    <div class="lp-form-offer__container">
      <div>
        <q-icon
          color="white"
          class="q-mb-md bg-primary q-pa-md border-radius-rounded"
          name="houseWithMoneySign"
          size="1.5rem"
        />

        <Teleport defer :disabled="$q.screen.gt.sm" to=".layout-main-footer__head">
          <div class="lp-form-offer__header--subtitle" v-text="title" />

          <div
            v-if="offerDeadlineFormatted && !offerExpired"
            class="lp-form-offer--deadline"
            v-text="offerDeadlineFormatted"
          />
          <div v-show="subtitle" class="lp-form-offer__text" v-text="subtitle" />
        </Teleport>
      </div>

      <q-btn v-if="!offerExpired" v-bind="btnPropsSubmit" @click="dialog = true" />

      <DialogFormStepper
        v-if="!isPreview"
        v-model="dialog"
        :factory-id="factoryId"
        persistent
        :steps="formStepsLpCtaOffer"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import { useVarPool } from '@/elr/listing_page/var_pool';
import { useStepsLpOffer } from '@/factories/formStepsFactory/lpOffer';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import { toDateTimeFormat } from '@/utils/time';

export type OfferBuyer = {
  vatNumber: string;
  idNumber: string;
  mobileNumber: string;
  occupation: string;
  firstName: string;
  lastName: string;
  fathersName: string;
  mothersName: string;
  placeOfBirth: string;
  dateOfBirth: string; // date_format:Y-m-d
  country: string;
  city: string;
  street: string;
  number: string;
  postalCode: string;
  ownershipPercentage: number | null; // max: 100 min: 0
  email: string;
};

export type OfferCompanyInfo = {
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  name: string;
  country: string;
  city: string;
  street: string;
  number: string;
  postalCode: string;
  phoneNumber: string;
  commercialRegistryNumber: string;
};

// Step one offer data
export type OfferFormDvg = OfferFormIndividual | OfferFormCompany | OfferFormPersonalBusiness;

export type OfferFormCommonDvg = {
  buyers: OfferBuyer[];
  listingId: string | number;
  submitterCompanyName: string;
  submitterType: 'agent' | 'individual' | null;
} & OfferFormPayment;

// last step
export type OfferFormPayment = {
  amount: number;
  accepts_terms: boolean;
  accepts_terms_cta: boolean;
  mortgageBankName: string;
  cashAmount: null | number;
  comments: string;
  signatureFile: File | null;
  mortgageAmount: null | number;
  mortgagePreApproval: '1' | '0' | null;
  mortgagePreApprovalFile: File | null;
  paymentOption: 'loan' | 'other' | null;
  signedBuyerRepresentationAgreement: boolean;
  utmSource: string | null;
  r_token: string;
};

export type OfferFormIndividual = {
  customerType: 'individual';
} & OfferFormCommonDvg;

export type OfferFormPersonalBusiness = {
  companyInfo: OfferCompanyInfo;
  customerType: 'personalBusiness';
} & OfferFormCommonDvg;

export type OfferFormCompany = {
  companyInfo: OfferCompanyInfo & {
    representativePhoneNumber: string;
  };
  customerType: 'company';
} & OfferFormCommonDvg;

const { listingConfig, isPreview } = storeToRefs(useListingStore());
const { cans } = useVarPool();

const { t } = useI18n(translations);
const { factoryId, getFormStepsLpOffer } = useStepsLpOffer();

const formStepsLpCtaOffer = getFormStepsLpOffer();

const dialog = ref(false);

const offerDeadline = computed(() => listingConfig.value?.offerSubmission?.deadline);

const offerExpired = computed(() => cans.value.includes('lp-offer-expired'));

// Teleport logic included, care
const title = computed(() => {
  let translationKey = Screen.gt.sm ? 'section.offer.title' : '';

  if (offerExpired.value) {
    translationKey = 'section.offer.titleExpired';
  } else if (offerDeadline.value) {
    translationKey = 'section.offer.titleDeadline';
  }

  return translationKey && t(translationKey);
});

// Teleport logic included, care
const offerDeadlineFormatted = computed(
  () =>
    offerDeadline.value &&
    toDateTimeFormat(offerDeadline.value, 'D MMM, YYYY h:m a')!
      .replace('am', t('section.offer.extra.am'))
      .replace('pm', t('section.offer.extra.pm'))
);

const subtitle = computed(() => {
  let translationKey = Screen.gt.sm ? 'section.offer.subtitle' : '';

  if (offerExpired.value) {
    translationKey = 'section.offer.subtitleExpired';
  } else if (offerDeadline.value) {
    translationKey = 'section.offer.subtitleDeadline';
  }

  return translationKey && t(translationKey);
});

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl q-mt-lg2',
  color: 'primary',
  label: t('btn.offer.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));
</script>
