<template>
  <div class="sp-list-no-data q-my-md q-pa-lg">
    <q-icon name="noresults" size="35px" class="q-ma-xs" color="secondary" />

    <div class="row q-ma-xs items-center justify-between">
      <div class="q-mb-md q-mr-md">
        <div class="text-subtitle1 text-secondary q-pt-sm" v-text="t('noMatch')" />
        <div class="text-body2 text-accent q-pt-xs" v-text="t('pleaseModify')" />
      </div>

      <q-btn v-if="hasActiveFilters" v-bind="btnPropsBtnClear" @click="onClickBtnClear" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Screen } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useClearFilters } from '@/composables/useClearFilters';
import translations from '@/i18n/translations/components/searchResultsNoData.json';

const { t } = useI18n(translations);
const { t: tGlobal } = useI18n();
const emit = defineEmits(['cleared']);

const { resetFilters, hasActiveFilters } = useClearFilters();

const onClickBtnClear = () => {
  const filtersCleared = resetFilters();
  if (filtersCleared) emit('cleared');
};

const btnPropsBtnClear = computed(() => ({
  class: `text-body2-bold border-radius-xl ${Screen.lt.sm && 'full-width'}`,
  color: 'primary',
  label: tGlobal('clearAllFilters'),
  noCaps: true,
  padding: '0.8rem 2.5rem',
  unelevated: true,
  outline: true,
}));
</script>

<style lang="scss">
@use 'sass:map';

.sp-list-no-data {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: white;
  border: 1px solid $util-2;
  border-radius: map.get($radius-sizes, 'md');
}
</style>
