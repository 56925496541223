<template>
  <div v-text="t('LPFORepresentativeAgent.sections.representativeInfo.title')" />
  <q-separator />

  <div class="f-form_grid_columns">
    <FInput
      :model-value="user?.first_name"
      disable
      :label="t('LPFORepresentativeAgent.fields.firstName.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInput
      :model-value="user?.last_name"
      disable
      :label="t('LPFORepresentativeAgent.fields.lastName.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />
  </div>
  <div class="f-form_grid_columns">
    <FInput
      :model-value="user?.email"
      disable
      :label="t('LPFORepresentativeAgent.fields.email.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInputPhone
      :model-value="user?.phone"
      disabled
      :error="!!errors?.phone?.[0]"
      :error-message="errors?.phone?.[0]"
      :label="t('LPFORepresentativeAgent.fields.phone.label')"
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />
  </div>

  <FInput
    v-model="modelValue.submitterCompanyName"
    :label="t('LPFORepresentativeAgent.fields.submitterCompanyName.label')"
    :error="!!errors?.submitterCompanyName?.[0]"
    :error-message="errors?.submitterCompanyName?.[0]"
    lazy-rules
    outlined
    :rules="[requiredDefault]"
    :required="true"
  />

  <template v-if="modelValue.customerType">
    <div v-text="t('LPFORepresentativeAgent.sections.participantInfo.title')" />

    <q-separator />

    <div class="f-form_grid_columns">
      <FInput
        v-model.trim="modelValue.contact.firstName"
        :error="!!errors?.['contact.firstName']?.[0]"
        :error-message="errors?.['contact.firstName']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.firstName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />

      <FInput
        v-model.trim="modelValue.contact.lastName"
        :error="!!errors?.['contact.lastName']?.[0]"
        :error-message="errors?.['contact.lastName']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.lastName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />
    </div>

    <div class="f-form_grid_columns">
      <FInput
        v-model.trim="modelValue.contact.email"
        :error="!!errors?.['contact.email']?.[0]"
        :error-message="errors?.['contact.email']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.email.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault, emailDefault, v => notEqualDefault(v, user?.email)]"
        :required="true"
      />

      <FInputPhone
        v-model="modelValue.contact.phone"
        :error="!!errors?.['contact.phone']?.[0]"
        :error-message="errors?.['contact.phone']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.phone.label')"
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />
    </div>

    <FInput
      v-if="modelValue.customerType === 'individual'"
      v-model.trim="modelValue.contact.vatNumber"
      :error="!!errors?.['contact.vatNumber']?.[0]"
      :error-message="errors?.['contact.vatNumber']?.[0]"
      :label="t('LPFORepresentativeAgent.fields.vatNumber.label')"
      lazy-rules
      outlined
    />

    <template v-else>
      <FInput
        v-model="modelValue.company.name"
        :error="!!errors?.['company.name']?.[0]"
        :error-message="errors?.['company.name']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.companyName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />

      <FInput
        v-model="modelValue.company.vatNumber"
        :error="!!errors?.['company.vatNumber']?.[0]"
        :error-message="errors?.['company.vatNumber']?.[0]"
        :label="t('LPFORepresentativeAgent.fields.companyVatNumber.label')"
        lazy-rules
        outlined
      />
    </template>
  </template>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translation from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';

defineProps<{
  errors?: Partial<Record<string, string[]>>;
}>();

const { t } = useI18n(translation);

const { email, neq, required } = useFormInputRules();
const { user } = useAuthStore();

const emailDefault = email();
const notEqualDefault = neq();
const requiredDefault = required();

type CustomerType = 'company' | 'individual' | 'personalBusiness';
type SubmitterType = 'agent' | 'individual';

type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  vatNumber?: string;
};

// Main
type FormData = {
  contact: Contact;
  customerType: CustomerType | null;
  submitterCompanyName: string;
  submitterType: SubmitterType | null;
  company: {
    name: string;
    vatNumber: string;
  };
};

const modelValue = defineModel<FormData>('modelValue', { required: true });
</script>
