<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form lp-form-auction-bid"
    greedy
    @submit.prevent="onSubmit"
    @validation-error="onValidationError"
  >
    <div>
      <template v-if="$q.screen.lt.md">
        <div class="submitting-as--text" v-text="t('LPAuctionBid.fields.customerType.label')" />

        <q-field
          :model-value="formData.customerType"
          borderless
          no-error-icon
          :rules="[requiredDefault]"
        >
          <template #control>
            <q-list class="customer-type-mobile--list">
              <q-expansion-item
                v-model="customerTypeMobileExpand"
                expand-icon="arrow_drop_down"
                group="customerTypes"
                :label="customerTypeMobileLabel"
              >
                <q-option-group
                  v-model="formData.customerType"
                  class="customer-type-mobile--option-group"
                  color="primary"
                  left-label
                  :options="customerTypeOptions"
                />
              </q-expansion-item>
            </q-list>
          </template>
        </q-field>
      </template>

      <FInputSelect
        v-else
        v-model="formData.customerType"
        behavior="menu"
        emit-value
        hide-bottom-space
        :label="t('LPAuctionBid.fields.customerType.label')"
        map-options
        no-error-icon
        :options="customerTypeOptions"
        outlined
        :placeholder="t('LPAuctionBid.fields.customerType.placeholder')"
        popup-content-class="f-input-select__content-class"
        :required="true"
        :rules="[requiredDefault]"
      />
    </div>

    <FInputToggle
      v-model="formData.submitterType"
      class="lp-form-auction-bid__checkbox-participation"
      :label="t('LPAuctionBid.fields.submitterType.label')"
      :options="submitterTypeOptions"
      :required="true"
      :rules="[requiredDefault]"
    />

    <template v-if="formData.submitterType">
      <LPFORepresentativeAgent
        v-if="formData.submitterType === 'agent'"
        v-model="formData"
        :errors="errors"
      />

      <LPFORepresentativeIndividual
        v-else-if="formData.submitterType === 'individual'"
        v-model="formData"
        :errors="errors"
      />

      <FInputTerms v-model="formData.accepts_terms" :error-message="errors.accepts_terms?.[0]">
        <template #default="{ urlTerms }">
          <span v-text="t('LPAuctionBid.fields.acceptsTerms.message.one')" />

          <a
            :href="urlTerms"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop
            v-text="t('LPAuctionBid.fields.acceptsTerms.message.two')"
          />

          <span v-text="t('LPAuctionBid.fields.acceptsTerms.message.three')" />
        </template>
      </FInputTerms>
    </template>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script lang="ts" setup>
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import LPFORepresentativeAgent from '@/components/ListingPage/Form/LPFormOfferDvg/LPFORepresentativeAgent.vue';
import LPFORepresentativeIndividual from '@/components/ListingPage/Form/LPFormOfferDvg/LPFORepresentativeIndividual.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import { useUtmSource } from '@/composables/useUtmSource';
import translation from '@/i18n/translations/components/formSteps.json';
import useListingStore from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId, Next } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: Next;
}>();

type Errors = Record<
  | Exclude<keyof typeof formData.value, 'contact'>
  | 'contact.firstName'
  | 'contact.lastName'
  | 'contact.email'
  | 'contact.phone',
  string[]
>;

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'next', p: Next): void;
}>();

const { resolveUtmSource } = useUtmSource();
const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);
const { storeAuctionBid } = useApiListing();

const listingStore = useListingStore();

const { listing, isPreview } = storeToRefs(listingStore);
const { getToken } = useCaptcha();
const { t } = useI18n(translation);

const { elQForm, onValidationError, required } = useFormInputRules();

const requiredDefault = required();

const bus = useEventBus(formStepperKey);
const customerTypeMobileExpand = ref(false);

const loading = ref(false);

const errors = ref<Partial<Errors>>({});

type CustomerType = 'company' | 'individual' | 'personalBusiness';
type SubmitterType = 'agent' | 'individual';

type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  vatNumber: string;
};

type CustomerTypeOption = {
  label: string;
  value: CustomerType;
};

// Main
export type FormCommon = {
  accepts_terms: boolean;
  additionalServices: boolean;
  contact: Contact;
  customerType: CustomerType | null;
  listingId: string | number;
  newsletterOptIn: boolean;
  r_token: string;
  company: {
    name: string;
    vatNumber: string;
  };
  requiresMortgage: boolean;
  submitterCompanyName: string;
  submitterType: SubmitterType | null;
  utmSource: string | null;
};

export type FormCompany = {
  company: {
    name: string;
    vatNumber: string;
  };
} & FormCommon;

export type FormIndividual = FormCommon;

export type FormPersonalBusiness = {
  company: {
    name: string;
    vatNumber: string;
  };
} & FormCommon;

type FormData = FormCompany | FormIndividual | FormPersonalBusiness;

// Main
const formData = ref<FormData>({
  accepts_terms: false,
  additionalServices: false,
  contact: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    vatNumber: '',
  },
  company: {
    name: '',
    vatNumber: '',
  },
  customerType: null,
  listingId: listing.value?.id ?? -1,
  newsletterOptIn: false,
  r_token: '',
  requiresMortgage: false,
  submitterCompanyName: '',
  submitterType: null,
  utmSource: resolveUtmSource(),
});

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: !formData.value.accepts_terms,
  form: `f-${props.formId}`,
  label: t('LPAuctionBid.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const customerTypeOptions = computed<CustomerTypeOption[]>(() => [
  { label: t('LPAuctionBid.fields.customerType.options.individual'), value: 'individual' },
  {
    label: t('LPAuctionBid.fields.customerType.options.personalBusiness'),
    value: 'personalBusiness',
  },
  { label: t('LPAuctionBid.fields.customerType.options.company'), value: 'company' },
]);

const customerTypeMobileLabel = computed(() => {
  let tKey = '';

  if (!formData.value.customerType) {
    tKey = 'LPAuctionBid.fields.customerType.placeholder';
  } else {
    tKey = `LPAuctionBid.fields.customerType.options.${formData.value.customerType}`;
  }

  return t(tKey);
});

const submitterTypeOptions = computed(() => [
  {
    label: t('LPAuctionBid.fields.submitterType.options.agent'),
    value: 'agent',
  },
  {
    label: t('LPAuctionBid.fields.submitterType.options.individual'),
    value: 'individual',
  },
]);

const onSubmit = async () => {
  errors.value = {};

  if (isPreview.value) return;

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  formData.value.r_token = await getToken('submitAuctionBid');

  storeAuctionBid(formData.value)
    .then(() => {
      emit('next', props.next || {});
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 409:
            listingStore.loadListing();

            emit('close');
            break;

          case 422:
            errors.value = response.data.errors;
            break;

          default:
            console.error('Unexpected error:', error);
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => formData.value.customerType,
  () => {
    elQForm.value?.resetValidation();

    customerTypeMobileExpand.value = false;

    formData.value = {
      ...formData.value,
      accepts_terms: false,
      company: {
        name: '',
        vatNumber: '',
      },
      submitterCompanyName: '',
    };
  }
);

watch(
  () => formData.value.submitterType,
  v => {
    if (!v) return;

    if (v === 'individual') {
      formData.value.company = {
        name: '',
        vatNumber: '',
      };
    }

    formData.value.contact = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      vatNumber: '',
    };
  }
);

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-auction-bid') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;

      case 'complete':
        loading.value = false;
        break;

      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-form-auction-bid__checkbox-participation {
  align-items: center;

  .q-checkbox__label {
    font-size: 0.875rem;
    font-weight: 800;
    color: #000;
  }
}

.customer-type-mobile--list {
  width: 100%;
  color: c.$secondary;
  border: 1px solid c.$util-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.customer-type-mobile--option-group {
  color: c.$secondary;

  .q-radio {
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 0.75rem 0.25rem 1rem;
  }
}

.submitting-as--text {
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.6;
  color: c.$secondary;
}
</style>
