import 'dayjs/locale/en-gb';

import type { OpUnitType } from 'dayjs';
import _dayjs from 'dayjs';
import localeEl from 'dayjs/locale/el';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

const dayjs = _dayjs;

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale(localeEl);

export { dayjs };

export const toDateFormat = (date: _dayjs.ConfigType, format = 'DD/MM/YYYY', locale = 'en') => {
  const makeDateLocal = dayjs(date, { locale, utc: true }).tz();

  return makeDateLocal.format(format);
};

export const toDateTimeFormat = (
  date: Date | string | _dayjs.Dayjs,
  format = 'DD/MM/YYYY HH:mm',
  locale = 'en'
) => toDateFormat(date, format, locale);

/**
 * Only accepts dates that are within the current month (or later)
 * and within current year (or later)
 */
export const filterPastDates = (dates: string[]) => {
  const currentDate = dayjs();

  const validDates = dates.filter(date => {
    const parsedDate = dayjs(date);

    return (
      parsedDate > currentDate ||
      (parsedDate.year() === currentDate.year() && parsedDate.month() >= currentDate.month())
    );
  });

  return validDates;
};

/**
 * Docs: https://day.js.org/docs/en/display/difference
 * @returns number based on unit value. Example: 'days' will return '10', 'milliseconds' will return '1060' etc.
 */
export const dateTimeDiff = (
  firstDate: _dayjs.ConfigType,
  secondDate: _dayjs.ConfigType,
  unit?: OpUnitType
) => dayjs(firstDate).diff(dayjs(secondDate), unit);

/**
 * Docs: https://day.js.org/docs/en/query/is-before
 * @returns boolean
 */
export const isBeforeDate = (
  firstDate: _dayjs.ConfigType,
  secondDate: _dayjs.ConfigType,
  unit?: OpUnitType
) => dayjs(firstDate).isBefore(dayjs(secondDate), unit);

/**
 * @returns Obj of days, daysShort, months, monthsShort to locale
 */
export const calendarLocaleData = (locale = 'en') => {
  dayjs.locale(locale);

  return {
    days: dayjs.weekdays(),
    daysShort: dayjs.weekdaysShort(),
    months: dayjs.months(),
    monthsShort: dayjs.monthsShort(),
  };
};

export const dateYearSubtract = (years: number, format = 'YYYY/MM') =>
  dayjs().subtract(years, 'years').format(format);
