<template>
  <div v-text="t('LPFORepresentativeAgent.sections.participantInfo.title')" />

  <q-separator />

  <div class="f-form_grid_columns">
    <FInput
      disable
      :error="!!errors?.['contact.firstName']?.[0]"
      :error-message="errors?.['contact.firstName']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.firstName.label')"
      lazy-rules
      :model-value="user?.first_name"
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInput
      disable
      :error="!!errors?.['contact.lastName']?.[0]"
      :error-message="errors?.['contact.lastName']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.lastName.label')"
      lazy-rules
      :model-value="user?.last_name"
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />
  </div>

  <div class="f-form_grid_columns">
    <FInput
      :model-value="user?.email"
      disable
      :error="!!errors?.['contact.email']?.[0]"
      :error-message="errors?.['contact.email']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.email.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault, emailDefault]"
      :required="true"
    />

    <FInputPhone
      disabled
      :error="!!errors?.['contact.phone']?.[0]"
      :error-message="errors?.['contact.phone']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.phone.label')"
      :model-value="user?.phone"
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />
  </div>

  <FInput
    v-if="modelValue.customerType === 'individual'"
    v-model.trim="modelValue.contact.vatNumber"
    :error="!!errors?.['contact.vatNumber']?.[0]"
    :error-message="errors?.['contact.vatNumber']?.[0]"
    :label="t('LPFORepresentativeIndividual.fields.vatNumber.label')"
    lazy-rules
    outlined
  />

  <template v-else>
    <FInput
      v-model="modelValue.company.name"
      :error="!!errors?.['company.name']?.[0]"
      :error-message="errors?.['company.name']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.companyName.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInput
      v-model="modelValue.company.vatNumber"
      :error="!!errors?.['company.vatNumber']?.[0]"
      :error-message="errors?.['company.vatNumber']?.[0]"
      :label="t('LPFORepresentativeIndividual.fields.companyVatNumber.label')"
      lazy-rules
      outlined
    />
  </template>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translation from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';

defineProps<{
  errors?: Partial<Record<string, string[]>>;
}>();

const { t } = useI18n(translation);
const { user } = storeToRefs(useAuthStore());

const { email, required } = useFormInputRules();

const requiredDefault = required();
const emailDefault = email();

type CustomerType = 'company' | 'individual' | 'personalBusiness';
type SubmitterType = 'agent' | 'individual';

type Contact = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  vatNumber?: string;
};

// Main
type FormData = {
  contact: Contact;
  customerType: CustomerType | null;
  submitterCompanyName: string;
  submitterType: SubmitterType | null;
  company: {
    name: string;
    vatNumber: string;
  };
};

const modelValue = defineModel<FormData>('modelValue', { required: true });
</script>
